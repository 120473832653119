import Enumeration from 'Lib/utilities/Enumeration';

const GenerativeFunctions = new Enumeration([
    { id: 1, key: 'Generate', name: 'Generate' },
    { id: 2, key: 'Rewrite', name: 'Rewrite' },
    { id: 3, key: 'Expand', name: 'Expand' },
    { id: 4, key: 'Shorten', name: 'Shorten' }
]);

export default GenerativeFunctions;
