// @ts-check
import { useHistory } from 'react-router-dom';

// Custom hook to get, set, and remove URL parameters
export default function useParam(param = '', defaultValue = '') {
    const history = useHistory();
    const query = () => new URLSearchParams(history.location.search);

    const value = query().get(param) || defaultValue;

    const setValue = (value, state, action = 'replace') => {
        const newQuery = query();

        newQuery.set(param, value);
        history[action]({
            search: newQuery.toString(),
            ...(state && { state })
        });
    };

    const setMany = (values, action = 'replace') => {
        const newQuery = query();

        for (const [key, value] of Object.entries(values)) {
            newQuery.set(key, value);
        }

        history[action]({
            search: newQuery.toString()
        });
    };

    const removeMany = (params, action = 'replace') => {
        const newQuery = query();

        for (const param of params) {
            newQuery.delete(param);
        }

        history[action]({
            search: newQuery.toString()
        });
    };

    const removeValue = (state, action = 'replace') => {
        const newQuery = query();

        newQuery.delete(param);
        history[action]({
            search: newQuery.toString(),
            ...(state && { state })
        });
    };

    const queryString = query().toString();

    // check if param is set
    const isSet = query().has(param) && value !== defaultValue;

    return { value, setValue, removeValue, isSet, setMany, removeMany, label: param, queryString };
}

// useManyParams hook
// Accepts an object of parameters with default values and returns an object with values, setMany, and removeMany functions.
export function useManyParams(params = {}) {
    const history = useHistory();
    const query = () => new URLSearchParams(history.location.search);

    const values = Object.fromEntries(Object.entries(params).map(([key, defaultValue]) => [key, query().get(key) || defaultValue]));
    const labels = Object.keys(params);

    const setMany = (values, action = 'replace') => {
        const newQuery = query();

        for (const [key, value] of Object.entries(values)) {
            newQuery.set(key, value);
        }

        history[action]({
            search: newQuery.toString()
        });
    };

    const setValue = (key, value, action = 'replace') => {
        const newQuery = query();

        newQuery.set(key, value);
        history[action]({
            search: newQuery.toString()
        });
    };

    const removeValues = (/** @type {string[]} */ labels, action = 'replace') => {
        const newQuery = query();

        for (const label of labels) {
            newQuery.delete(label);
        }

        history[action]({
            search: newQuery.toString()
        });
    };

    const removeAll = (action = 'replace') => {
        const newQuery = query();

        for (const label of labels) {
            newQuery.delete(label);
        }
        history[action]({
            search: newQuery.toString()
        });
    };

    const queryString = (/** @type {[String, String][]?} */ moreValues, /** @type {String[]?} */ removeValues) => {
        const newQuery = query();

        if (moreValues) {
            for (const [key, value] of moreValues) {
                newQuery.set(key, value);
            }
        }
        if (removeValues) {
            for (const key of removeValues) {
                newQuery.delete(key);
            }
        }

        return newQuery.toString();
    };

    return { values, setMany, removeValues, removeAll, setValue, labels, queryString };
}

// // Example usage:

// For multiple uses, create custom hooks so the parameter is fixed for each.
export function useGSFilter() {
    return useParam('_gsFilter');
}

export function useGSSearch() {
    return useParam('_gsSearch');
}

function Example() {
    const filter = useGSFilter();
    const search = useGSSearch();

    return (
        <>
            <button onClick={() => filter.setValue('all')}>Set filter</button>
            <button onClick={() => filter.removeValue()}>Remove filter</button>
            {filter.isSet && <div>{filter.value}</div>}

            <button onClick={() => search.setValue('search')}>Set search</button>
            <button onClick={() => search.removeValue()}>Remove search</button>
            <div>{search.value}</div>
        </>
    );
}
