import Enumeration from 'Lib/utilities/Enumeration';

import PortalTypes from '../app/portalTypes';

const ChatRoleTypes = new Enumeration([
    { id: 1, key: 'Manager', name: 'Management Company', color: '#8cff7d' },
    { id: 2, key: 'Tenant', name: 'Tenant', color: '#c0e2ff', path: 'isSharedWithTenant' },
    { id: 3, key: 'Owner', name: 'Owner', color: '#ffc0c0', path: 'isSharedWithOwner' },
    { id: 4, key: 'Vendor', name: 'Vendor', color: '#ddbfff', path: 'isSharedWithVendor' },
    { id: 5, key: 'Applicant', name: 'Applicant', color: '#ddbfff', path: 'isSharedWithApplicant' }
]);

export const getValues = (portalTypeID, values) => {
    ChatOptions(portalTypeID).forEach(option => {
        const found = values.recipients.find(recipient => recipient.value == option.value);
        values[option.path] = found ? '1' : '0';
    });
    
    return values;
};

export const renderSharedWithText = (portalTypeID, message) => {
    switch (parseInt(portalTypeID)) {
        case PortalTypes.Manager: {
            const sharedWithTypes = ['Management Company'];

            for (const [key, value] of Object.entries(message)) {
                if (value == '1') {
                    if (key == 'isSharedWithTenant') sharedWithTypes.push('Residents');
                    if (key == 'isSharedWithVendor') sharedWithTypes.push('Vendors');
                }
            }

            return `Shared with ${sharedWithTypes.join(', ')}`;
        }
        case PortalTypes.Tenant: {
            const sharedWithTypes = ['Residents', 'Management Company'];

            for (const [key, value] of Object.entries(message)) {
                if (value == '1' && key == 'isSharedWithVendor') {
                    sharedWithTypes.push('Vendors');
                }
            }

            return `Shared with ${sharedWithTypes.join(', ')}`;
        }
        case PortalTypes.Owner: {
            const sharedWithTypes = ['Owners', 'Management Company'];

            for (const [key, value] of Object.entries(message)) {
                if (value == '1' && key == 'isSharedWithVendor') {
                    sharedWithTypes.push('Vendors');
                }
            }

            return `Shared with ${sharedWithTypes.join(', ')}`;
        }
        case PortalTypes.Vendor: {
            const sharedWithTypes = ['Vendors', 'Management Company'];

            for (const [key, value] of Object.entries(message)) {
                if (value == '1' && key == 'isSharedWithTenant') {
                    sharedWithTypes.push('Residents');
                }
            }

            return `Shared with ${sharedWithTypes.join(', ')}`;
        }
        case PortalTypes.Applicant: {
            const sharedWithTypes = ['Applicant', 'Management Company'];

            return `Shared with ${sharedWithTypes.join(', ')}`;
        }
        default:
            break;
    }
};

export const getInitialChatValues = portalTypeID => {
    switch (parseInt(portalTypeID)) {
        case PortalTypes.Manager:
        case PortalTypes.Vendor:
        case PortalTypes.Tenant:
        case PortalTypes.Owner:
        case PortalTypes.Applicant:
            return {
                recipients: [...ChatOptions(portalTypeID)],
                isSharedWithManager: '1',
                isSharedWithVendor: '1',
                isSharedWithTenant: '1'
            };
        default:
            break;
    }
};

export const ChatOptions = portalTypeID => {
    switch (parseInt(portalTypeID)) {
        case PortalTypes.Manager:
            return [
                {
                    value: 1,
                    label: 'Management Company',
                    path: 'isSharedWithManager',
                    isDisabled: true,
                    isFixed: true
                },
                {
                    value: 2,
                    label: 'Residents',
                    path: 'isSharedWithTenant'
                },
                {
                    value: 4,
                    label: 'Vendors',
                    path: 'isSharedWithVendor'
                }
            ];
        case PortalTypes.Owner:
            return [
                {
                    value: 1,
                    label: 'Management Company',
                    path: 'isSharedWithManager',
                    isDisabled: true,
                    isFixed: true
                },
                {
                    value: 2,
                    label: 'Residents',
                    path: 'isSharedWithTenant'
                },
                {
                    value: 4,
                    label: 'Vendors',
                    path: 'isSharedWithVendor'
                }
            ];
        case PortalTypes.Tenant:
            return [
                {
                    value: 1,
                    label: 'Management Company',
                    path: 'isSharedWithManager',
                    isDisabled: true,
                    isFixed: true
                },
                {
                    value: 2,
                    label: 'Residents',
                    path: 'isSharedWithTenant',
                    isDisabled: true,
                    isFixed: true
                },
                {
                    value: 4,
                    label: 'Vendors',
                    path: 'isSharedWithVendor'
                }
            ];
        case PortalTypes.Vendor:
            return [
                {
                    value: 1,
                    label: 'Management Company',
                    path: 'isSharedWithManager',
                    isDisabled: true,
                    isFixed: true
                },
                {
                    value: 2,
                    label: 'Residents',
                    path: 'isSharedWithTenant'
                }
            ];
        case PortalTypes.Applicant:
            return [
                {
                    value: 1,
                    label: 'Management Company',
                    path: 'isSharedWithManager',
                    isDisabled: true,
                    isFixed: true
                },
                {
                    value: 5,
                    label: 'Applicants',
                    path: 'isSharedWithApplicant'
                }
            ];
        default:
            break;
    }
};

export default ChatRoleTypes;
