import Enumeration from 'Lib/utilities/Enumeration';

const ChatObjectTypes = new Enumeration([
    { id: 1, key: 'WorkOrder', name: 'Work Order' },
    { id: 2, key: 'Lease', name: 'Lease' },
    { id: 3, key: 'Portfolio', name: 'Portfolio' },
    { id: 4, key: 'Vendor', name: 'Vendor' },
    { id: 5, key: 'Applicant', name: 'Applicant' }
]);

export default ChatObjectTypes;
