import Enumeration from 'Lib/utilities/Enumeration';

const Tones = new Enumeration([
    { id: 1, key: 'Same', name: 'Same' },
    { id: 2, key: 'Friendly', name: 'Friendly' },
    { id: 3, key: 'Professional', name: 'Professional' },
    { id: 4, key: 'Witty', name: 'Witty' },
    { id: 5, key: 'Heartfelt', name: 'Heartfelt' },
    { id: 6, key: 'Educational', name: 'Educational' }
]);

export default Tones;
